import { Hit } from 'react-instantsearch-core'
import { Product } from '../../types/algolia/product'
import { Context } from '../../types/frontastic'

export default class ProductListService {
    private static refinedPages: number[] = []

    public static handleProductClick = (hit: Hit<Product>, context: Context): void => {
        const paginationLimit = context.project.data.productList.productsPerPage
        const searchParams = new URLSearchParams(window.location.search)
        const searchPage = searchParams.get('page')
        const urlPage = searchPage ? parseInt(searchPage, 10) : 1
        const productPage = Math.ceil(hit.__position / paginationLimit)

        if (urlPage !== productPage) {
            searchParams.set('page', productPage.toString(10))
        }

        window.history.replaceState(
            null,
            '',
            `?${searchParams.toString()}`,
        )
    }

    public static addRefinedPage(page: number): void {
        if (!ProductListService.refinedPages.includes(page)) {
            ProductListService.refinedPages.push(page)
        }
    }

    public static isPageAlreadyRefined(page: number): boolean {
        return ProductListService.refinedPages.includes(page)
    }

    public static getTitleWhenFilterByBrand(title: string, brand: string): string {
        if (!brand) {
            return title
        }

        return `${title} von ${brand}`
    }
}
