import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import {Configure, InstantSearch} from 'react-instantsearch-dom'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import {useDeviceType} from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import ProductListService from 'anwr_sport2000/src/js/services/algolia/ProductListService'
import ClientFactory from '../../../services/algolia/ClientFactory'
import Hits from '../../../patterns/organisms/Algolia/Hits'
import EmptyResult from '../../../patterns/organisms/Algolia/EmptySearch'
import DesktopFilter from '../../../patterns/organisms/Algolia/Filter/Desktop'
import RoutingService from '../../../services/algolia/RoutingService'
import {setCategorySeo, setCategoryUrl, setNameCategory} from '../../../store/actions/ProductListActions'
import Sort from '../../../patterns/organisms/Algolia/Sort'
import CountProduct from '../../../patterns/organisms/Algolia/Count'
import BlogService from '../../../services/blog'
import SidebarMenu from '../../../patterns/organisms/Algolia/SidebarMenu'
import SlideMenu from '../../../patterns/organisms/Algolia/SlideMenu'
import useVirtualPageView from '../../../services/hook/useVirtualPageView'
import Breadcrumb from '../../breadcrumb/tastic'
import {useRouter} from 'next/router'
import debounce from 'lodash.debounce'


function AlgoliaProductListTastic({
  node, context, data,
}) {
  if (!data || !context) {
    return null
  }

  const wishlistState = useSelector((state) => state.wishlist?.wishlist?.data?.lineItems || [])
  const {categoryUrl} = useSelector((state) => state['product-list-algolia'])
  const activeFilter = useSelector((state) => state['product-list-algolia'].openFilter)
  const isMobile = useDeviceType() === 'mobile'
  const isTablet = useDeviceType() === 'tablet'
  const isShowSlider = isMobile || isTablet

  const {indexName} = context.project.configuration.algolia.languages[context.locale]
  const resultsState = data.stream
  const sidebarCategories = resultsState?.sidebarCategories ?? []
  const initFacets = resultsState?.rawResults?.[0]?.facets

  const category = node.configuration.entity || {}
  const extendCurrentCategory = resultsState?.extendCurrentCategory || {}
  if (category.projectSpecificData) {
    category.projectSpecificData.children = extendCurrentCategory?.children
    category.projectSpecificData.parent = extendCurrentCategory?.parent
  }

  const {algoliaIndexName} = context?.projectConfiguration || {}

  const isCategoryPage = node.nodeType === 'category'
  const gtmCategoryName = isCategoryPage ? node?.configuration?.entity?.name : node.name
  const gtmCategoryId = isCategoryPage ? node?.configuration?.entity?.categoryId : node.nodeType

  let categoryPageHeadline = node.name
  if (data.headline) {
    categoryPageHeadline = data.headline
  } else if (category && category.projectSpecificData) {
    categoryPageHeadline = category.projectSpecificData.categoryHeadline ? category.projectSpecificData.categoryHeadline : category.name
  }
  const categorySeoTitle = getTranslation(
    node.configuration.seoTitle,
    context.locale,
    context.project.defaultLanguage,
  ).text
  const categorySeoDescription = getTranslation(
    node.configuration.seoDescription,
    context.locale,
    context.project.defaultLanguage,
  ).text

  const {setDependencies} = useVirtualPageView()
  const dispatcher = useDispatch()
  const router = useRouter()
  const searchQuery = router.query.query
  const initQuery = data.stream.configureProps?.filter((configProp) => configProp.prop === 'query')[0]?.value || searchQuery || ''

  const DEBOUNCE_TIME = 400
  const [count, setCount] = useState(0)
  const [searchState, setSearchState] = useState(RoutingService.urlToSearchState(router.query, initQuery, initFacets, count))
  const [searchUrl, setSearchUrl] = useState('')
  const [refinementListBrand, setRefinementListBrand] = useState([])

  const searchConfigurations = ClientFactory.generateConfigure(data.stream.configureProps)
  const isDisplayFilter = data.displayFilters ?? true
  const isDisplaySidebar = data.displaySidebar ?? true
  const isDisplaySorting = data.displaySorting ?? true
  const hasSidebarCategory = sidebarCategories?.length > 0
  const sortOptions = context.projectConfiguration.algoliaSortOptions ?? []
  const algoliaClient = ClientFactory.getSearchClient(context)

  const [blogTiles, setBlogTiles] = useState([])
  const [facetCategory, setFacetCategory] = useState(null)
  const initialFilter = data.stream.initialFilter ?? ''
  const displaySidebarByResult = data.displaySidebarByResult ?? false
  const selectedCategorySidebar = data.selectedCategorySidebar ?? ''

  const onSearchStateChange = debounce((updatedSearchState, isUpdateUrl = true) => {
    const {refinementList} = updatedSearchState

    const queryUrl = RoutingService.searchStateToUrl(updatedSearchState, !isCategoryPage)
    const brandQueryUrl = isCategoryPage ? RoutingService.generateBrandQueryUrl(updatedSearchState) : ''
    const pathname = RoutingService.brandQueryToUrl(window.location.pathname, brandQueryUrl)
    let url = `${pathname}${queryUrl}`

    if (categoryUrl) {
      url = `${categoryUrl}${queryUrl}`
    }

    if (isUpdateUrl) {
      router.push(url.toLocaleLowerCase(), undefined, {shallow: true})
    }

    setSearchUrl(url)
    setDependencies(url)

    dispatcher(setCategoryUrl({
      categoryUrl: null,
    }))

    // if (!isEmpty(refinementList)) {
    ClientFactory.queryInformationCategory(refinementList, context, initialFilter, displaySidebarByResult).then((res) => {
      setFacetCategory(res.results[0]?.facets?.categories)
    })

    setSearchState(updatedSearchState)
  }, 200)

  // only apply for store page when filter category
  const handleScrollAfterFilterCategory = () => {
    if (node.nodeType !== 'store' && !displaySidebarByResult) {
      return
    }
    if (window.location.search.indexOf('categorySidebar') < 0) {
      return
    }

    const scrollTarget = document.getElementsByClassName('product-list--wrapper')[0]
    if (scrollTarget) {
      setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: scrollTarget.offsetTop,
        })
      }, 100)
    }
  }
  useEffect(() => {
    handleScrollAfterFilterCategory()
  }, [])

  useEffect(() => {
    let mounted = true
    const {categoryKey} = category.projectSpecificData || {}

    if (categoryKey) {
      BlogService.getBlogTitle(categoryKey).then((res) => {
        if (res.data?.marketingTiles && mounted) {
          setBlogTiles(res.data.marketingTiles)
        }
      })
    }

    const handlePopstate = () => {
      const updatedSearchState = RoutingService.urlToSearchState(window.location, initQuery)
      onSearchStateChange(updatedSearchState, false)
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (categoryUrl || initQuery) {
      onSearchStateChange({
        ...searchState,
      })
    }
  }, [categoryUrl, initQuery])

  useEffect(() => {
    if (activeFilter && count < 1) {
      setCount(1)
    }
  }, [activeFilter])

  useEffect(() => {
    let categoryName = categoryPageHeadline
    let tempCategorySeoTitle = categorySeoTitle

    if (searchState.refinementList?.brand?.length === 1) {
      const firstBrandInFilter = searchState.refinementList?.brand?.[0]
      setRefinementListBrand(searchState.refinementList?.brand)
      categoryName = ProductListService.getTitleWhenFilterByBrand(categoryName, firstBrandInFilter)
      tempCategorySeoTitle = ProductListService.getTitleWhenFilterByBrand(tempCategorySeoTitle, firstBrandInFilter)
    }

    dispatcher(setNameCategory({
      nameCategory: categoryName,
    }))

    dispatcher(setCategorySeo({
      categorySeoTitle: tempCategorySeoTitle,
      categorySeoDescription,
    }))
  }, [searchState])

  return (
    <div className={'product-list--wrapper'}>
      <Breadcrumb data={data} node={node}/>
      <div className={'product-list--component'} data-insights-index={indexName}>
        <InstantSearch
          searchClient={algoliaClient}
          indexName={indexName}
          onSearchStateChange={onSearchStateChange}
          searchState={searchState}
          resultsState={resultsState}
        >
          <Configure
            {...searchConfigurations}
            query={searchState.query}
            userToken={context.session.account.accountId}
          />

          {isDisplayFilter && (
            <DesktopFilter
              category={category}
              context={context}
              headline={data.headline}
              categoryPageHeadline={categoryPageHeadline}
              node={node}
              backgroundColor={data.backgroundColor}
              algoliaIndexName={algoliaIndexName}
              refinementListBrand={refinementListBrand}
            />
          )}

          <EmptyResult searchFAQ={data.searchFAQ} context={context} node={node}/>

          <div className={'flex-col lg:flex-row lg:mt-8 lg:flex'}>
            {isDisplaySidebar && !isShowSlider && (
              <SidebarMenu
                items={sidebarCategories || []}
                facetCategory={facetCategory}
                nameCategory={category.name}
                currentCategory={category}
                node={node}
                displaySidebarByResult={displaySidebarByResult}
                selectedCategorySidebar={selectedCategorySidebar}
              />
            )}
            {isDisplaySidebar && isShowSlider && (
              <SlideMenu
                facetCategory={facetCategory}
                currentCategory={category}
                node={node}
                categoryTree={sidebarCategories || []}
                displaySidebarByResult={displaySidebarByResult}
              />
            )}

            <div className={'flex-grow'}>
              <div className={'sort-algolia'}>
                <CountProduct searchResults/>
                <Sort
                  defaultRefinement={indexName}
                  isDisplaySorting={isDisplaySorting}
                  items={sortOptions}
                />
              </div>

              <Hits
                gtmCategoryName={gtmCategoryName}
                gtmCategoryId={gtmCategoryId}
                context={context}
                searchUrl={searchUrl}
                blogTiles={blogTiles}
                wishlistState={wishlistState}
                hasSidebarCategory={hasSidebarCategory}
                isDisplaySidebar={isDisplaySidebar}
                refererStore={data.refererStore}
              />
            </div>
          </div>
        </InstantSearch>
      </div>
    </div>
  )
}

AlgoliaProductListTastic.propTypes = {
  node: PropTypes.objectOf(PropTypes.any).isRequired,
  tastic: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  context: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  query: PropTypes.any,
  searhFAQ: PropTypes.any,
}

export default tastify({
  translate: true,
  connect: {
    node: true, tastic: true, route: true, context: true, wishlist: true,
  },
})(AlgoliaProductListTastic)
